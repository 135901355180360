(function() {
    var tabsWrapper = document.querySelectorAll('.js-grid-tabs'),
        completeUrl = window.location.href,
        substring = "?reviews=",
        currentUrl = new URL(completeUrl);

    if (tabsWrapper.length > 0) {
        const gridTabs = document.querySelectorAll('.js-grid-tab'),
            gridTabsContentElements = document.querySelectorAll('.js-grid-tab-content'),
            gridTabsImage = document.querySelectorAll('.js-grid-tab-image'),
            gridTabImageClass = 'js-grid-tab-image',
            tabsWrappedClass = 'grid-tabs--wrapped',
            tabContentActiveClass = 'grid-tab-content-active',
            tabActiveClass = 'grid-tab-active';

        if (completeUrl.includes(substring)) {
            localStorage.removeItem('activeTab');

            const tabName = completeUrl.split(substring)[1].toLowerCase();
            for (var i = 0; i < gridTabs.length; i++) {
                gridTabs[i].classList.toggle(tabActiveClass, gridTabs[i].dataset.tabName.toLowerCase() === tabName);
                gridTabsContentElements[i].classList.toggle(tabContentActiveClass, gridTabsContentElements[i].dataset.tabName.toLowerCase() === tabName);
                if (gridTabs[i].classList.contains(tabActiveClass)) {
                    localStorage.setItem('activeTab', gridTabs[i].getAttribute('data-grid-tab'));
                }
            }
        }

        for (var i = 0; i < gridTabs.length; i++) {
            const tabImageId = gridTabs[i].getAttribute('data-grid-image');

            if (i === 0 && !localStorage.getItem('activeTab')) {
                localStorage.setItem('activeTab', gridTabs[i].getAttribute('data-grid-tab'));
            } else if (gridTabs[i].getAttribute('data-grid-tab') === localStorage.getItem('activeTab')) {
                const gridTabContentId = gridTabs[i].getAttribute('data-grid-tab');

                hideAllTabsAndContent(gridTabsContentElements, gridTabs, tabContentActiveClass, tabActiveClass);
                showActiveTabAndContent(gridTabs[i], gridTabContentId, tabActiveClass, tabContentActiveClass);
            }

            if (tabImageId) {
                addImageToTab(gridTabs[i], tabImageId, gridTabImageClass)
            }

            gridTabs[i].addEventListener('click', function (e) {
                e.preventDefault();
                const gridTabContentId = this.getAttribute('data-grid-tab');

                hideAllTabsAndContent(gridTabsContentElements, gridTabs, tabContentActiveClass, tabActiveClass);
                showActiveTabAndContent(this, gridTabContentId, tabActiveClass, tabContentActiveClass);

                currentUrl.searchParams.set('reviews', this.dataset.tabName.toLowerCase());
                history.replaceState(null, '', currentUrl.href);

                localStorage.setItem('activeTab', this.getAttribute('data-grid-tab'));
            });
        }

        function hideAllTabsAndContent(tabContents, tabs, activeContentClass, activeTabClass) {
            for (var j = 0; j < tabContents.length; j++) {
                tabContents[j].classList.remove(activeContentClass);
            }

            for (var t = 0; t < tabs.length; t++) {
                tabs[t].classList.remove(activeTabClass);
            }
        }

        function showActiveTabAndContent(el, id, tabActiveClass, tabContentActiveClass) {
            el.classList.add(tabActiveClass);
            document.querySelector('#' + id).classList.add(tabContentActiveClass);
        }

        function addImageToTab(el, id, gridTabImageClass) {
            const childEl = document.getElementById(id),
                imageUrl = childEl.getAttribute('data-tab-image'),
                imageElements = el.getElementsByClassName(gridTabImageClass);

            for (var i = 0; i < imageElements.length; i++) {
                imageElements[i].src = imageUrl;
            }
        }

        function checkForFlexWrap(elements, tabsWrapper, tabsWrappedClass) {
            for (var j = 0; j < tabsWrapper.length; j++) {
                tabsWrapper[j].classList.remove(tabsWrappedClass);
            }

            var previousElementOffset = 0;
            for (var i = 0; i < elements.length; i++) {

                if (previousElementOffset === 0) {
                    previousElementOffset = elements[i].offsetLeft;
                } else {
                    if (elements[i].offsetLeft <= previousElementOffset) {
                        elements[i].parentElement.classList.add(tabsWrappedClass);
                        break;
                    } else {
                        previousElementOffset = elements[i].offsetLeft;
                    }
                }
            }
        }

        window.addEventListener("resize", Foundation.util.throttle(function () {
            checkForFlexWrap(gridTabs, tabsWrapper, tabsWrappedClass);
        }, 150));

        checkForFlexWrap(gridTabs, tabsWrapper, tabsWrappedClass);
    }
}());
